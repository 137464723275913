<template>
  <div>
    <div
      @click="handleClick"
    >
      <slot
        name="text"
      />
    </div>
    <SlideOverPanel
      :open="open"
      @close="open = false"
    >
      <template v-slot:panelHeading>
        <slot name="panelHeading" />
      </template>

      <slot />
    </SlideOverPanel>
  </div>
</template>

<script>
import SlideOverPanel from '@components/SlideOverPanel'

export default {
  components: {
    SlideOverPanel
  },

  props: {
    close: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      open: false
    }
  },

  watch: {
    close(val) {
      if (val) {
        this.open = false
      }
    }
  },

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.open = true
      }
    }
  }
}
</script>
