<template>
  <TooltipButton
    class="hover:text-red-500 duration-150"
    @click="deleteQuestion"
  >
    <template v-slot:content>
      <Icon
        v-if="question.uuid"
        icon="Bin"
        view-box="0 0 24 24"
        class="w-5 h-5 fill-none"
      >
        <Bin />
      </icon>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4 text-gray-600"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </template>
    <template v-slot:tooltip>
      Delete question
    </template>
  </TooltipButton>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Bin from '@components/Icons/Bin'
import TooltipButton from '@components/TooltipButton'

import organisationExamsApi from '@api/organisationExams'

import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    Bin,
    TooltipButton
  },

  props: {
    question: {
      type: Object,
      default: null
    },
    exam: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  methods: {
    deleteQuestion() {
      if (!this.question.uuid) {
        this.$emit('deleteQuestion', this.question)
        return
      }

      return organisationExamsApi.deleteQuestion(this.organisationId, this.exam.uuid, this.question.uuid)
        .then(question => {
          this.$emit('fetchQuestions')
        })
        .catch(error => {
          throw error
        })
    }
  }
}
</script>
