<template>
  <div v-if="state === states.READY">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs :breadcrumbs="breadcrumbs"></BasePageBreadcrumbs>
        <BasePageHeading>{{ exam.name }}</BasePageHeading>
      </div>
      <BasePageActions>
        <CreateEditTest
          :exam="exam"
          @examUpdated="fetchExam()"
        />

        <PreviewTestButton
          :disabled="!questions.length"
          :exam="exam"
          :questions="questions"
        />

        <DeleteTestBuilderTestButton :exam="exam" />

        <BaseButton @click="finishCreateEditExam">
          Save &amp; Exit
        </BaseButton>
      </BasePageActions>
    </BasePageHeader>

    <BaseWrapper>
      <section>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <EditQuestion
            :selected-question="selectedQuestion"
            :questions="questions"
            :exam="exam"
            :loading="loading"
            :status="questionStatus"
            class="order-2 md:order-1"
            @questionUpdated="updateQuestions($event)"
            @deleteQuestion="deleteQuestion($event)"
            @fetchQuestions="fetchQuestions"
          />

          <CreatedQuestions
            class="md:order-2"
            :questions="questions"
            :loading="loading"
            :exam="exam"
            :selected-question="selectedQuestion"
            @editQuestion="editQuestion($event)"
            @questionOrderChanged="changeQuestionOrder($event)"
            @addQuestion="addQuestion"
            @deleteQuestion="deleteQuestion($event)"
            @fetchQuestions="fetchQuestions"
          />
        </div>
      </section>
    </BaseWrapper>
  </div>
  <div v-else-if="state === states.LOADING">
    <BasePageHeader>
      <div>
        <BasePageBreadcrumbs></BasePageBreadcrumbs>
        <BasePageHeading></BasePageHeading>
      </div>
    </BasePageHeader>
    <Loader />
  </div>
  <div v-else-if="state === states.ERROR">
    <BaseErrorBlock />
  </div>
</template>

<script>
import Loader from '@components/Loader'
import CreateEditTest from '@components/TestBuilder/CreateEditTest'
import PreviewTestButton from '@components/TestBuilder/PreviewTestButton'
import DeleteTestBuilderTestButton from '@components/TestBuilder/DeleteTestBuilderTestButton'
import EditQuestion from '@components/TestBuilder/EditQuestion'
import CreatedQuestions from '@components/TestBuilder/CreatedQuestions'
import organisationExamsApi from '@api/organisationExams'

import states from '@api/states'
import { mapGetters } from 'vuex'

export default {
  components: {
    Loader,
    CreateEditTest,
    PreviewTestButton,
    DeleteTestBuilderTestButton,
    EditQuestion,
    CreatedQuestions
  },

  data() {
    return {
      states,

      loading: false,
      error: false,

      questions: [],
      selectedQuestion: {},
      questionStatus: '',
      exam: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId',
      organisationName: 'organisations/name'
    }),

    /**
     * @return {Array}
     */
    breadcrumbs() {
      return [
        {
          name: this.organisationName
        },
        {
          name: 'Custom Tests',
          to: 'test-builder'
        }
      ]
    },

    /**
     * @return {string}
     */
    state() {
      if (this.error) {
        return states.ERROR
      }
      if (this.loading) {
        return states.LOADING
      }
      if (!this.exam) {
        return states.LOADING
      }

      return states.READY
    }
  },

  mounted() {
    this.fetchExam()
  },

  methods: {
    fetchExam() {
      this.loading = true

      return organisationExamsApi.showExam(this.organisationId, this.$route.params.exam)
        .then(exam => {
          this.loading = false

          this.exam = exam
          this.fetchQuestions()
        })
        .catch(error => {
          this.loading = false
          this.error = error
          throw error
        })
    },

    fetchQuestions(currentQuestion) {
      this.loading = true

      return organisationExamsApi.fetchQuestions(this.organisationId, this.$route.params.exam)
        .then(questions => {
          this.questions = questions
          this.loading = false

          if (!currentQuestion) {
            this.selectedQuestion = this.questions[0]
          } else {
            this.selectedQuestion = this.questions.find(question => question.body === currentQuestion.body)
          }

          if (!this.questions.length) {
            this.addQuestion()
          }

          setTimeout(() => {
            this.questionStatus = ''
          }, 3500)
        })
        .catch(error => {
          this.loading = false
          this.error = error
          throw error
        })
    },

    finishCreateEditExam() {
      this.updateQuestions().then(() => {
        this.$router.push({ name: 'test-builder' })
      })
    },

    updateQuestions(currentQuestion = this.selectedQuestion) {
      this.loading = true

      return organisationExamsApi.updateQuestions(this.organisationId, this.exam.uuid, { questions: this.questions })
        .then(questions => {
          this.questionStatus = 'Question saved'
          this.fetchQuestions(currentQuestion)
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          this.error = error
          throw error
        })
    },

    editQuestion(questionToBeEdited) {
      if (questionToBeEdited.uuid) {
        this.selectedQuestion = this.questions.find(question => question.uuid === questionToBeEdited.uuid)
      } else {
        const indexOfQuestion = this.questions.indexOf(questionToBeEdited)
        this.selectedQuestion = this.questions.find((question, index) => index === indexOfQuestion)
      }
    },

    changeQuestionOrder(question) {
      this.questions.forEach((question, index) => {
        question.position = index + 1
      })
      this.updateQuestions()
    },

    goToLastQuestion() {
      this.selectedQuestion = this.questions[this.questions.length - 1]
    },

    addQuestion() {
      this.questions.push({
        position: this.questions.length + 1,
        body: '',
        image: null,
        answers: [
          {
            body: '',
            correct: false
          },
          {
            body: '',
            correct: false
          },
          {
            body: '',
            correct: false
          },
          {
            body: '',
            correct: false
          }
        ]
      })
      this.goToLastQuestion()
    },

    deleteQuestion(questionForRemoval) {
      this.questions = this.questions.filter(question => question.position !== questionForRemoval.position)
      this.goToLastQuestion()
    }
  }
}
</script>
