<template>
  <div>
    <Modal
      :open="modalOpen"
      :can-close="false"
      @close="modalOpen = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex items-center px-6 pb-6">
          <Icon
            width="28px"
            height="28px"
            view-box="0 0 60 60"
            class="mr-2"
          >
            <Hourglass />
          </Icon>
          <h3 class="font-medium text-lg">
            Your time is up!
          </h3>
        </div>
      </div>

      <div class="p-6">
        <p class="mb-6">
          Thank you for taking the time to take this test. Your results have been sent to the company for review.
        </p>
        <BaseButton
          @click="$emit('finish')"
        >
          Back
        </BaseButton>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'
import Hourglass from '@components/Icons/Celebration'

export default {
  components: {
    Modal,
    Icon,
    Hourglass
  },

  props: {
    modalOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>
