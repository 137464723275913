<template>
  <div class="p-6 bg-white rounded shadow">
    <div class="flex items-center justify-between">
      <h2 class="text-xl font-medium">
        Editing Question {{ question.position }}
      </h2>

      <DeleteTestBuilderQuestionButton
        v-if="questions.length"
        :question="question"
        :exam="exam"
        @deleteQuestion="$emit('deleteQuestion', question)"
        @fetchQuestions="$emit('fetchQuestions')"
      />
    </div>
    <Loader v-if="loading && !exam" />

    <transition
      mode="out-in"
      enter-active-class="transition-all duration-500 ease-out"
      leave-active-class="transition-all duration-150 ease-in"
      enter-class="opacity-0"
      leave-class="opacity-100"
      enter-to-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <form
        class="mt-8 space-y-8"
        @submit.prevent
      >
        <section>
          <label
            for="question"
            class="flex items-center mb-1 text-sm text-gray-600"
          >
            Question text
          </label>

          <textarea
            id="question"
            v-model="$v.question.body.$model"
            class="w-full border-gray-300 rounded-lg resize-none form-textarea focus:border-gray-800 focus:shadow-none"
            rows="4"
          />

          <ErrorsInline v-if="hasErrors">
            <span v-if="!$v.question.body.$model">Please enter question text</span>
          </ErrorsInline>
        </section>

        <section>
          <div class="">
            <label
              for="cover_photo"
              class="flex items-center mb-1 text-sm text-gray-600"
            >
              Question image (optional)
            </label>
            <div>
              <EditQuestionImage
                :question="question"
                @imageUpdated="handleImageUpdate($event)"
              />
            </div>
          </div>
        </section>

        <fieldset class="divide-y divide-dashed">
          <div
            v-for="(option, optionIdx) in question.answers"
            :key="optionIdx"
            class="py-6 space-x-8 md:flex md:items-center"
          >
            <div class="w-full">
              <label
                for="`${option}-${optionIdx}`"
                class="text-sm text-gray-600"
              >Answer {{ optionIdx + 1 }}</label>

              <div class="flex items-center w-full space-x-8 md:justify-between">
                <input
                  :id="`${option}-${optionIdx}`"
                  v-model="$v.question.answers.$each[optionIdx].body.$model"
                  class="flex-1 block w-full mt-1 rounded form-input"
                  @focus="$event.target.select()"
                >
                <div class="flex items-center">
                  <span
                    class="flex mr-4"
                  >
                    <label class="flex items-center">
                      <span class="mr-2 text-xs">
                        Correct?
                      </span>
                      <input
                        type="radio"
                        name="group"
                        :checked="option.correct"
                        class="form-radio text-secondary"
                        @change="handleCorrectAnswer(optionIdx, $event.target.value)"
                      />
                    </label>
                  </span>
                  <TooltipButton
                    class="duration-150 hover:text-red-500"
                    :class="{ 'pointer-events-none' : question.answers.length < 3 }"
                    @click="deleteAnswer(optionIdx)"
                  >
                    <template v-slot:content>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-4 h-4 text-gray-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </template>
                    <template v-slot:tooltip>
                      Delete answer
                    </template>
                  </TooltipButton>
                </div>
              </div>

              <ErrorsInline v-if="hasErrors">
                <span v-if="!$v.question.answers.$each[optionIdx].body.$model">Please enter answer text</span>
              </ErrorsInline>
            </div>
          </div>
        </fieldset>

        <a
          href="javascript:;"
          class="block text-sm underline"
          @click="addAnswer"
        >
          Add answer
        </a>

        <div class="flex items-center justify-end h-12">
          <div class="relative w-full">
            <ErrorsInline v-if="hasErrors">
              <span v-if="!$v.question.answers.atLeastOneCorrectAnswerChosen">Please choose a correct answer</span>
            </ErrorsInline>
            <transition
              appear
              enter-active-class="transition-all duration-300 ease-out"
              leave-active-class="transition-all duration-300 ease-in"
              enter-class="transform translate-x-5 opacity-0"
              leave-class="transform translate-x-0 opacity-100"
              enter-to-class="transform translate-x-0 opacity-100"
              leave-to-class="transform translate-x-5 opacity-0"
            >
              <p
                v-if="status"
                class="absolute text-sm font-medium transition-all duration-300 ease-out text-secondary"
              >
                {{ status }}
              </p>
            </transition>
          </div>
          <BaseButton
            variant="ghost"
            class="whitespace-no-wrap"
            :loading="loading"
            @click="updateQuestion"
          >
            Save question
          </BaseButton>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import ErrorsInline from '@components/ErrorsInline'
import EditQuestionImage from '@components/TestBuilder/EditQuestionImage'
import TooltipButton from '@components/TooltipButton'
import DeleteTestBuilderQuestionButton from '@components/TestBuilder/DeleteTestBuilderQuestionButton'
import Loader from '@components/Loader'

import Vue from 'vue'

const atLeastOneCorrectAnswerChosen = (questionOptions) => questionOptions.some(option => option.correct === true)

export default {
  components: {
    ErrorsInline,
    EditQuestionImage,
    TooltipButton,
    DeleteTestBuilderQuestionButton,
    Loader
  },

  mixins: [validationMixin],

  props: {
    selectedQuestion: {
      type: Object,
      default: null
    },
    exam: {
      type: Object,
      default: null
    },
    questions: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      errors: [],
      hasErrors: false,

      // Empty Option
      answer: {
        body: '',
        correct: false
      },

      question: {}
    }
  },

  validations() {
    return {
      question: {
        body: {
          required
        },

        answers: {
          atLeastOneCorrectAnswerChosen,
          $each: {
            body: {
              required
            },
            correct: {}
          }
        }
      }
    }
  },

  watch: {
    selectedQuestion: {
      handler(selectedQuestion) {
        if (selectedQuestion) {
          this.question = this.selectedQuestion
        }
      },
      immediate: true

    }
  },

  methods: {
    /**
     * Adds a new answer to the end of the array
     */
    addAnswer() {
      const newAnswer = JSON.parse(JSON.stringify(this.answer))
      this.question.answers.push(newAnswer)
    },

    deleteAnswer(optionIdx) {
      this.question.answers = this.question.answers.filter((option, index) => index !== optionIdx)
    },

    updateQuestion() {
      this.errorMessages = []

      this.$v.question.$touch()
      this.hasErrors = this.$v.question.$anyError

      if (this.hasErrors) {
        return
      }
      // @NOTE - the type will eventually be
      // based on the question format chosen by the test creator
      const questionWithType = {
        ...this.question,
        type: 'choice'
      }

      this.$emit('questionUpdated', questionWithType)
    },

    handleCorrectAnswer(optionIdx, value) {
      Vue.set(this.question.answers[optionIdx], 'correct', true)

      const incorrectOptions = this.question.answers.filter((option, index) => index !== optionIdx)
      incorrectOptions.map(option => {
        Vue.set(option, 'correct', false)
      })
    },

    handleImageUpdate(image) {
      this.question.image = image
    }
  }
}
</script>
