<template>
  <div>
    <SlideOverPanelTrigger
      :close="close"
      :disabled="disabled"
    >
      <template v-slot:text>
        <TooltipButton
          :class="['hover:text-secondary duration-150', disabled ? 'text-gray-400' : 'currentColor']"
          @click="modalOpen = true"
        >
          <template v-slot:content>
            <BaseButton
              variant="salmon"
            >
              <template slot="iconMiddle">
                <Icon
                  view-box="0 0 24 24"
                  class="w-5 h-5"
                >
                  <Eye />
                </Icon>
              </template>
            </BaseButton>
          </template>
          <template v-slot:tooltip>
            Preview test <span v-if="disabled">(add at least one question)</span>
          </template>
        </TooltipButton>
      </template>

      <template v-slot:default>
        <PreviewTestContainer
          :exam="examAndQuestions"
          @timeIsUp="timeIsUp"
          @finish="close = true"
        />

        <PreviewTestTimeUpModal
          v-if="timeUpModalOpen"
          :modal-open="timeUpModalOpen"
          @finish="close = true"
        />
      </template>
    </SlideOverPanelTrigger>
  </div>
</template>

<script>
import PreviewTestContainer from '@components/TestBuilder/PreviewTestContainer'
import SlideOverPanelTrigger from '@components/SlideOverPanelTrigger'
import TooltipButton from '@components/TooltipButton'
import PreviewTestTimeUpModal from '@components/TestBuilder/PreviewTestTimeUpModal'
import Icon from '@components/Icons/Icon'
import Eye from '@components/Icons/Eye'

export default {
  components: {
    PreviewTestContainer,
    SlideOverPanelTrigger,
    TooltipButton,
    PreviewTestTimeUpModal,
    Icon,
    Eye
  },

  props: {
    exam: {
      type: Object,
      default: null
    },
    questions: {
      type: Array,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      close: false,
      timeUpModalOpen: false
    }
  },

  computed: {
    // @NOTE: This should probably all come in a "real" test format
    // from the backend
    examAndQuestions() {
      return {
        ...this.exam,
        questions: this.questions.map(question => {
          return {
            ...question,
            type: 'choice',
            image_url: question.image && `https://res.cloudinary.com/${question.image.cloudName}/image/upload/q_70/v${question.image.version}/${question.image.publicId}.${question.image.format}`
          }
        }),
        questionCount: this.questions.length,
        fullName: this.exam.name,
        slug: this.exam.name
      }
    }
  },

  methods: {
    timeIsUp() {
      this.timeUpModalOpen = true
    }
  }
}
</script>
