<template>
  <div>
    <div
      v-if="!imageUrl && processing"
      class="relative h-16 w-16 flex flex-col justify-center"
    >
      <Loader />
    </div>
    <transition
      enter-active-class="transition-all duration-300 ease-out"
      leave-active-class="transition-all duration-300 ease-in"
      enter-class="opacity-0 transform translate-x-5"
      leave-class="opacity-100 transform translate-x-0"
      enter-to-class="opacity-100 transform translate-x-0"
      leave-to-class="opacity-0 transform translate-x-5"
    >
      <div
        v-if="imageUrl"
        class="flex-shrink-0"
      >
        <img
          class="w-full block"
          :src="imageUrl"
          alt="Question Image"
        >
        <div class="flex justify-end mt-1">
          <button
            class="text-xs mt-1 underline hover:border-red-400 hover:text-red-500 duration-150 ease-out"
            @click="deleteImage"
          >
            Delete image
          </button>
        </div>
      </div>
    </transition>

    <div
      v-if="!processing && !imageUrl"
      class="flex p-2 border-2 border-gray-300 border-dashed rounded"
    >
      <div class="bg-white space-y-6">
        <div>
          <label class="block text-sm font-medium text-gray-700 sr-only">
            Question Image
          </label>
          <div class="flex items-center">
            <svg
              class="mx-auto h-16 w-16 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="text-left pl-2">
              <div class="text-sm text-gray-600">
                <label
                  for="file-upload"
                  class="relative cursor-pointer bg-white rounded-md font-medium text-secondary hover:text-secondary-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <span class="pr-2">Upload an image</span>
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    :disabled="processing"
                    class="sr-only"
                    @click="uploadError = ''"
                    @change="uploadFiles($event.target.files[0])"
                  >
                </label>
              </div>
              <p class="text-xs text-gray-500">
                PNG or JPG up to 5MB
              </p>
            </div>

            <ErrorsInline
              v-if="uploadError"
              class="relative ml-4"
            >
              <span>{{ uploadError }}</span>
            </ErrorsInline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloudinaryApi from '@api/cloudinary'
import organisationExamsApi from '@api/organisationExams'
import ErrorsInline from '@components/ErrorsInline'
import Loader from '@components/Loader'

import { mapGetters } from 'vuex'

export default {
  components: {
    ErrorsInline,
    Loader
  },

  props: {
    v: {
      type: Object,
      default: null
    },
    question: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      cloudinaryCloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
      cloudinaryApiKey: process.env.VUE_APP_CLOUDINARY_API_KEY,
      processing: false,
      imageUrl: '',
      uploadError: ''
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  watch: {
    question: {
      immediate: true,
      deep: true,
      handler(question) {
        if (question.image) {
          this.imageUrl = `https://res.cloudinary.com/${this.question.image.cloudName}/image/upload/q_70/v${this.question.image.version}/${this.question.image.publicId}.${this.question.image.format}`
        } else {
          this.imageUrl = ''
        }
      }
    }
  },

  methods: {
    uploadFiles(file) {
      this.imageUrl = null
      this.processing = true

      const imageData = new FormData()

      let timestamp = Math.round(new Date().getTime() / 1000)
      let publicId = `${this.organisationId}-${timestamp}`
      const uploadPreset = 'exam_question_images'

      imageData.append('upload_preset', uploadPreset)
      imageData.append('public_id', publicId)
      imageData.append('file', file)
      imageData.append('api_key', this.cloudinaryApiKey)
      imageData.append('timestamp', timestamp)

      return organisationExamsApi.getCloudinarySignature({ publicId, timestamp, uploadPreset })
        .then(response => {
          imageData.append('signature', response.signature)

          cloudinaryApi.uploadImage(imageData, this.cloudinaryCloudName)
            .then(image => {
              this.processing = false

              this.$emit('imageUpdated', {
                publicId: image.public_id,
                version: image.version,
                format: image.format,
                cloudName: this.cloudinaryCloudName
              })
            })
            .catch(error => {
              this.uploadError = 'Oops! Something went wrong. Please try again.'
              this.processing = false
              throw error
            })
        })
        .catch(error => {
          this.processing = false
          throw error
        })
    },

    deleteImage() {
      this.imageUrl = ''
      this.$emit('imageUpdated', null)
    }
  }
}
</script>
