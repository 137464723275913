<template>
  <BaseContainer>
    <TestBuilderEdit />
  </BaseContainer>
</template>

<script>
import TestBuilderEdit from '@components/TestBuilder/TestBuilderEdit'

export default {
  components: {
    TestBuilderEdit
  },

  beforeMount() {
    window.addEventListener('beforeunload', this.launchReloadWarning)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('beforeunload', this.launchReloadWarning)
    })
  },

  methods: {
    /**
     * Launch reload page generic browser alert
     */
    launchReloadWarning(event) {
      event.preventDefault()
      // Chrome requires returnValue to be set
      event.returnValue = ''
    }
  }
}
</script>
