<template>
  <div>
    <div>
      <header
        class="w-full relative flex items-center p-6 shadow z-10 h-16 lg:h-auto bg-white justify-start"
      >
        <div :class="['mx-auto', {'overflow-x-auto max-w-screen-xl pb-4 px-6 xl:px-0' : questionCount > 20}]">
          <ul
            class="hidden md:flex"
          >
            <li
              v-for="n in questionCount"
              :key="n"
              class="px-2"
            >
              <a
                href="javascript:;"
                class="px-2 text-gray-600"
                :class="questionNumberStyles(n)"
                @click="handleNavigation(n)"
              >
                {{ n }}
              </a>
            </li>
          </ul>
        </div>

        <Timer
          v-if="exam.isTimed"
          class="self-start ml-4"
          :time-in-minutes="exam.minutes"
          @time-is-up="timeIsUp"
        />
      </header>

      <div
        v-for="(question, index) in filteredQuestions()"
        :key="index"
      >
        <div
          v-if="index === questionIndex"
          class="bg-white"
        >
          <section
            v-if="typeChoice"
            class="md:flex justify-center w-full"
          >
            <div
              class="w-full lg:w-2/3 lg:h-screen bg-gray-100 p-6 lg:py-12 lg:px-16 sticky"
            >
              <img
                v-if="question.image_url"
                class="w-full mx-auto md:max-w-lg xl:max-w-xl"
                :src="question.image_url"
                :alt="question.body"
              />
              <img
                v-else
                class="w-full"
                src="~@assets/images/test-placeholder.png"
                alt="Question Image Placeholder"
              />
            </div>

            <div
              :class="['w-full lg:h-screen p-6 lg:py-12 lg:px-16 lg:shadow-left overflow-y-scroll lg:w-1/3']"
            >
              <div class="bg-gray-200 h-1 mb-6">
                <div
                  class="bg-secondary h-1"
                  :style="{ 'width': progress + '%'}"
                />
              </div>

              <h1 class="text-4xl font-medium">
                Question {{ index + 1 }}
              </h1>

              <!-- COMPONENTIZE -->
              <template v-if="typeChoice">
                <p
                  class="text-lg mt-6"
                  v-html="question.body"
                >
                </p>

                <ul class="mt-4">
                  <li
                    v-for="(answer, answerIndex) in question.answers"
                    :key="answerIndex"
                  >
                    <label class="inline-flex items-center mt-4">
                      <input
                        v-model="choiceResponses[questionIndex]"
                        type="radio"
                        class="form-radio text-secondary h-6 w-6"
                        :name="`${answer.body}-index-${index + 1}`"
                        :value="answer.body"
                      />
                      <span class="ml-2">{{ answer.body | capitalize }}</span>
                    </label>
                  </li>
                </ul>
              </template>
              <!-- END COMPONENTIZE -->

              <div class="inline-flex my-8">
                <BaseButton
                  class="mr-4"
                  :disabled="questionIndex < 1"
                  variant="inverse"
                  @click="prev"
                >
                  Back
                </BaseButton>
                <BaseButton
                  v-if="!isLastQuestion"
                  :disabled="!canPressNext"
                  @click="next()"
                >
                  Next
                </BaseButton>
                <BaseButton
                  v-if="isLastQuestion"
                  @click="$emit('finish')"
                >
                  Finish
                </BaseButton>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Timer from '@components/Timer'

import marked from 'marked'
import { mapGetters } from 'vuex'

export default {
  components: {
    Timer
  },

  props: {
    exam: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      marked,

      endModalOpen: false,
      completedModalOpen: false,

      questionIndex: 0,
      choiceResponses: []
    }
  },

  computed: {
    ...mapGetters({
      questions: 'exams/questions',
      examBySlug: 'exams/examBySlug'
    }),

    /**
     * @return {String}
     */
    progress() {
      return (this.questionIndex / this.questionCount) * 100
    },

    /**
     * @return {Boolean}
     */
    typeChoice() {
      return this.exam.instruction_slug === 'choice'
    },

    /**
     * @return {Number}
     */
    questionCount() {
      return this.exam.questions.length
    },

    /**
     * @return {Boolean}
     */
    isLastQuestion() {
      return !!(this.questionIndex === this.questionCount - 1)
    },

    /**
     * @return {String}
     */
    nextText() {
      return this.isLastQuestion ? 'Finish' : 'Next'
    },

    /**
     * Can the user press the next button?
     *
     * Mostly used for likely-type questions.
     *
     * @return {Boolean}
     */
    canPressNext() {
      return true
    }
  },

  created() {
    this.restart()
  },

  methods: {
    restart() {
      this.questionIndex = 0

      // Creates response arrays ready to be filled by the user
      this.choiceResponses = Array(this.questionCount).fill(null)
    },

    handleNavigation(index) {
      this.questionIndex = index - 1
    },

    filteredQuestions() {
      return this.exam.questions
    },

    /**
     * Take the user to the next question, or submit response if last
     */
    next() {
      if (this.submitInProgress) {
        return
      }

      if (this.questionIndex + 1 < this.questionCount) {
        return this.questionIndex++
      }
    },

    prev() {
      if (this.questionCount > 0) {
        return this.questionIndex--
      }
    },

    questionNumberStyles(index) {
      return [
        { 'inline-block border-b-2 border-secondary pb-1 font-semibold leading-none text-gray-800': this.questionIndex === index - 1 },
        { 'text-secondary': this.hastheQuestionBeenAnswered(index) }
      ]
    },

    hastheQuestionBeenAnswered(index) {
      return this.choiceResponses[index - 1] !== null
    },

    timeIsUp() {
      this.$emit('timeIsUp')
    }
  }
}
</script>
