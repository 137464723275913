<template>
  <div :class="['rounded-lg', backgroundColor]">
    <div class="flex items-center">
      <div class="flex-shrink-0">
        <Icon
          view-box="0 0 20 20"
          :class="['h-5 w-5', textColor]"
        >
          <component :is="icon" />
        </Icon>
      </div>
      <div :class="['ml-3 text-sm font-medium', textColor]">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import { titleCase } from '@utils/titleCase'

const variants = {
  warning: {
    text: 'text-yellow-500',
    background: 'bg-yellow-50'
  },

  info: {
    text: 'text-gray-600',
    background: 'bg-transparent'
  }
}

export default {
  components: {
    Icon
  },

  props: {
    type: {
      type: String,
      default: 'warning'
    },
    variant: {
      type: String,
      default: 'warning'
    }
  },

  data() {
    return {
      titleCase
    }
  },

  computed: {
    /**
     * @return {String}
     */
    backgroundColor() {
      return variants[this.variant].background
    },

    /**
     * @return {String}
     */
    textColor() {
      return variants[this.variant].text
    },

    /**
     * @return {Function}
     */
    icon() {
      return () => import('@components/Icons/' + titleCase(this.variant))
    }

  }
}
</script>
