<template>
  <div class="shadow rounded bg-white p-6">
    <div class="flex items-center justify-between">
      <h2 class="text-xl font-medium">
        Questions
      </h2>
      <p class="text-gray-600 text-xs">
        Drag and drop to re-order
      </p>
    </div>

    <div
      ref="questions"
      :style="{ maxHeight: '50vh' }"
      class="overflow-y-auto"
    >
      <transition
        mode="out-in"
        enter-active-class="transition-all duration-500 ease-out"
        leave-active-class="transition-all duration-150 ease-in"
        enter-class="opacity-0"
        leave-class="opacity-100"
        enter-to-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <draggable
          v-if="questions.length"
          tag="ul"
          class="mt-8 space-y-6"
          ghost-class="drag"
          :list="questions"
          :animation="200"
          :force-fallback="true"
          @change="questionOrderChanged"
        >
          <li
            v-for="question in questions"
            :key="question.uuid"
            class="relative flex shadow-sm rounded min-w-0 cursor-pointer"
            @click="editQuestion(question)"
          >
            <div :class="['flex-shrink-0 flex items-center justify-center w-16 text-white font-medium text-xl rounded-l', question.uuid === selectedQuestion.uuid ? 'bg-secondary' : 'bg-gray-500']">
              <span
                v-if="loading"
              >
                <Loader />
              </span>
              <span v-else>{{ question.position }} </span>
            </div>
            <div class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r truncate">
              <div class="flex-1 px-4 py-4 text-sm truncate">
                <p
                  v-if="question.body"
                  href="#"
                  class="font-medium"
                >
                  {{ question.body }}
                </p>
                <p
                  v-else
                  href="#"
                  class="font-medium text-gray-500"
                >
                  Your question...
                </p>
              </div>
              <div class="flex-shrink-0 pr-2">
                <button
                  type="button"
                  class="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-500 rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  <span class="sr-only">Drag and drop exam to reposition</span>
                  <svg
                    class="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                  </svg>
                </button>
              </div>
            </div>
            <DeleteTestBuilderQuestionButton
              class="flex flex-col justify-center mx-4"
              :question="question"
              :exam="exam"
              @deleteQuestion="$emit('deleteQuestion', question)"
              @fetchQuestions="$emit('fetchQuestions')"
            />
          </li>
        </draggable>
      </transition>
    </div>

    <button
      v-if="questions.length && !loading"
      class="w-full px-4 py-4 focus:outline-none rounded focus:border-gray-400 border-dashed border-2 border-gray-200 mt-6 shadow-sm hover:border-gray-400 hover:text-gray-600 duration-150 ease-out"
      @click="addQuestion"
    >
      Add question
    </button>

    <div
      v-if="loading"
      class="mt-8"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import Loader from '@components/Loader'
import draggable from 'vuedraggable'
import DeleteTestBuilderQuestionButton from '@components/TestBuilder/DeleteTestBuilderQuestionButton'

export default {
  components: {
    draggable,
    DeleteTestBuilderQuestionButton,
    Loader
  },

  props: {
    questions: {
      type: Array,
      default: () => []
    },
    selectedQuestion: {
      type: Object,
      default: null
    },
    exam: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    questionOrderChanged(event) {
      this.$emit('questionOrderChanged', event.moved.element)
    },

    editQuestion(question) {
      window.scrollTo(0, 0)
      this.$emit('editQuestion', question)
    },

    addQuestion() {
      this.$emit('addQuestion')
      this.$nextTick(() => {
        this.$refs.questions.scrollTop = this.$refs.questions.scrollHeight
      })
    }
  }
}
</script>
