import axios from 'axios'

export default {
  /**
   * Uploads a signed image to Cloudinary
   *
   * @return {Object}
   */
  uploadImage (data, cloudName) {
    return axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, data)
      .then(response => {
        return response.data
      })
  }
}
