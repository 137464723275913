<template>
  <portal to="slide-overs">
    <section class="block relative z-60">
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        leave-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        leave-class="opacity-100"
        enter-to-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="open"
          class="absolute inset-0 bg-black bg-opacity-75"
          aria-hidden="true"
        ></div>
      </transition>

      <div
        class="fixed inset-y-0 right-0 pl-10 max-w-full flex mt-16"
      >
        <transition
          enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
          enter-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
          leave-class="translate-x-0"
          leave-to-class="translate-x-full"
        >
          <div
            v-if="open"
            class="w-screen w-full"
          >
            <div
              class="h-full flex flex-col pb-6 bg-white shadow-xl overflow-y-scroll"
            >
              <div class="px-4 sm:px-6 py-2">
                <div class="flex items-start justify-between">
                  <slot name="panelHeading" />

                  <div class="ml-3 h-7 flex items-center">
                    <button
                      class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      @click="$emit('close')"
                    >
                      <span class="sr-only">Close panel</span>
                      <svg
                        class="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="relative flex-1">
                <slot />
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
  </portal>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    open: {
      handler(newValue) {
        if (newValue) {
          // Stop background scroll when modal is open
          document.body.style.setProperty('overflow', 'hidden')
        } else {
          document.body.style.removeProperty('overflow')
        }
      },
      immediate: true
    }
  },

  beforeDestroy() {
    document.body.style.removeProperty('overflow')
  }
}
</script>
